<template>
  <el-container >
    <div class="sys">
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
      <div class="sy">{{username}}</div>
    </div>
    <el-header style="height:100px;">
      <el-image
      class="wid275"
      :src="require('../images/feduplogo.png')"
      fit="fill"></el-image>
                            <el-dropdown placement="bottom-start" class="position el-dropdown-link">
                                <span style="display: flex;" class="">
                                    <i class="el-icon-s-custom"></i>
                                    <div style="margin-left: 10px; font-size: 18px;line-height: 28px;color: #7A8B99;">{{username}}</div>
                                    <i style="font-size: 18px;margin-top: 4px;margin-left: 5px;" class="el-icon-arrow-down"></i>
                                  </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="look">个人信息</el-dropdown-item>
                                    <el-dropdown-item @click.native="outLogin">退出登录</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            
    </el-header>
    <el-container>
      <el-aside width="210px">
        <nav-menu></nav-menu>
      </el-aside>
      <el-container><!-- :style="'height:'+height+'px'" 去除手动获取屏幕适配，改为内容充满  -->
        <el-main style="height: max-content;" >
          <router-view v-on:getrefresh="refresh" v-if="flag"></router-view>
        </el-main>
      </el-container>
    </el-container>

  </el-container>
</template>

<script>

import NavMenu from './navMenu.vue'
export default {
  name: 'Page',
  props: {
  },
  data(){
    return {
   //   height:document.body.clientHeight-95,
      flag:false,
      username:localStorage.getItem('username'),
    }
  },
  created(){
    let _this = this;
      // window.onresize = function(){
      //   _this.height =  document.body.clientHeight - 95;
      // }
      setTimeout(function(){
        _this.flag = true;
      },1000);
  },
  components: {NavMenu},
  methods: {
    outLogin(){
       localStorage.setItem("Authorization", null);
       localStorage.setItem("Iad", null);
       this.$router.push("/login");
    },
    look(){
       this.$router.push("/views/personal");
    },
    refresh(){
      NavMenu.methods.getinit();
    }
  }
}
</script>


<style scoped lang="scss">
.sys{
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #F7FAFC;
}
  .sy{
    color: #999;
    pointer-events: none;
    display: inline-block;
    line-height: 100px;
    font-size: 35px;
    transform: rotateZ(-45deg);
    text-align: center;
    opacity: 0.2;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    width: 16.66666667%;
    z-index: 20;
  }
  .el-container{
    position: relative;
    background-color: #F7FAFC;
  }
.el-header, .el-footer {
    border-bottom: solid 1px #e6e6e6;
  }
  .el-menu{
    text-align: left;
    min-height: 100%;
  }
  .el-aside {
    min-height: calc(100vh - 60px);
  }
  
  .el-main {
    padding: 0px;
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: #fff;
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  .position{
    width: 28px;
    height: 28px;
    font-size: 28px;
    position: absolute;
    right: 200px;
    top: 30px;
  }
  .wid275{
    width: 125px;
    height: 44px;
    position: absolute;
    left: 40px; 
    top: 30px;
  }
  .el-button{
    
    font-size: 16px;
    font-weight: bold;
  }
  
.el-menu--horizontal > .el-menu-item{
    font-weight: bold;
}
.el-form--inline .el-form-item__label{
    font-weight: bold;
}
</style>
