
const config={
    login:'api/admin/login',//登录
    logout:'api/admin/logout',//登录
    loginCode:'api/admin/loginCode',//验证
    user:'api/admin/user',//用户列表
    userInfo:'api/admin/userInfo',//用户详情
    userChangeStatus:'api/admin/userChangeStatus',//变更用户状态
    jur:'api/admin/jur',//获取用户菜单权限
    roleChangeStatus:'api/admin/roleChangeStatus',//变更角色状态
    roleInfo:'api/admin/roleInfo',//获取角色详情
    role:'api/admin/role',//角色列表
    bindMenu:'api/admin/bindMenu',//角色绑定菜单
    bindMenuList:'api/admin/bindMenuList',//角色绑定菜单信息
    menu:'api/admin/menu',//菜单列表
    menuInfo:'api/admin/menuInfo',//菜单详情
    menuChangeStatus:'api/admin/menuChangeStatus',//变更菜单状态
    bindOrg:'api/admin/bindOrg',//为菜单绑定接口
    bindOrgList:'api/admin/bindOrgList',//回显
    route:'api/admin/route',//路由列表
    routeInfo:'api/admin/routeInfo',//路由详情
    routeChangeStatus:'api/admin/routeChangeStatus',//变更路由状态
    userInfoSelf:'api/admin/userInfoSelf',//个人信息
    userInfoEdit:'api/admin/userInfoEdit',//个人信息修改
    order:'api/admin/order',//订单列表
    order_detail:'api/admin/order_detail',//订单详情
    handle_logs:'api/admin/handle_logs',//订单处理记录
    user_coupon:'api/admin/user_coupon',//获取可用优惠卷(订单可用)
    operator_logs:'api/admin/operator_logs',//订单操作记录
    order_schedule:'api/admin/order_schedule',//订单进度发送记录
    order_schedule_model:'api/admin/order_schedule_model',//订单进度模板列表
    send_schedule:'api/admin/send_schedule',//手动发送模板进度
    model_message:'api/admin/model_message',//消息推送模板列表
    next_reason:'api/admin/select/next_reason',//下次处理原因
    order_type:'api/admin/select/order_type',//订单类型
    oid_type:'api/admin/select/oid_type',//证件类型
    tchannel:'api/admin/select/tchannel',//投诉渠道
    ttype:'api/admin/select/ttype',//投诉类型
    order_message:'api/admin/order_message',//消息推送记录
    cx_type:'api/admin/select/cx_type',//投诉类型(包括123级联动)
    t_method:'api/admin/select/t_method',//投诉方式
    claims:'api/admin/select/claims',//索赔情况
    cause_analysis:'api/admin/select/cause_analysis',//原因分析
    pay_channel:'api/admin/select/pay_channel',//支付渠道
    sclist:'api/admin/sclist',//成功订单列表
    bank_item_list:'api/admin/bank_item_list',//收款信息选项列表
    add_bank_item:'api/admin/add_bank_item',//新增银行选项
    edit_bank_item:'api/admin/edit_bank_item',//编辑银行选项
    bank_item_detail:'api/admin/bank_item_detail',//银行选项详情  
    delete_bank_item:'api/admin/delete_bank_item',//删除银行选项  
    bank:'api/admin/bank',//推送补充收款信息  
    bank_list:'api/admin/bank_list',//推送收款信息列表  
    bank_detail:'api/admin/bank_detail',//推送收款信息详情 
    bank_detail_edit:'api/admin/bank_detail_edit',//再次发送/保存推送补充收款信息
    bank_history:'api/admin/bank_history',//补充收款信息历史记录列表
    bank_h_info:'api/admin/bank_h_info',//推送补充收款信息历史详情
    fail_order:'api/admin/fail_order',//失败订单列表
    pic:'api/admin/pic',//订单图片
    pic_download:'api/admin/pic_download',//订单图片下载
    aircompany_info:'api/admin/aircompany_info',//根据code获取航空公司信息
    claim_information:'api/admin/claim_information',//理赔详情
    tempSave:'api/admin/tempSave',//保存订单信息
    order_export:'api/admin/order_export',//订单导出
    ts_item:'api/admin/ts_item',//投诉函插入项目选项
    action_list:'api/admin/action_list',//行为记录列表
    preview:'api/admin/preview',//预览投诉函
    ts_model_list:'api/admin/ts_model_list',//投诉函模板列表
    ts_add:'api/admin/ts_add',//投诉函模板添加 
    ts_edit:'api/admin/ts_edit',//投诉函模板编辑
    ts_delete:'api/admin/ts_delete',//删除投诉函模板
    ts_logs:'api/admin/ts_logs',//添加记录
    exchange_by_order:'api/admin/exchange_by_order',//用户主动联系客服  
    exchange_all:'api/admin/exchange_all',//所有订单用户主动联系客服列表  
    exchange_detail:'api/admin/exchange_detail',//用户联系详情 
    exchange:'api/admin/exchange',//用户主动联系编辑
    order_relation:'api/admin/order_relation',//主从订单详情
    sendInvitation:'api/admin/sendInvitation',//发送修改信息 列表
    sendInvitation_info:'api/admin/sendInvitation_info',//发送修改信息详情
    image_type:'api/admin/image_type',//图片类型添加
    image_type_detail:'api/admin/image_type_detail',//图片类型详情
    file_upload:'api/admin/file_upload',//上传图片
    action_log:'api/admin/action_log',//添加行为记录
    question_type:'api/admin/question_type',//上传图片
    selectquestion_type:'api/admin/select/question_type',//附件模板问题分类
    selectaction_log_type:'api/admin/select/action_log_type',//行为记录
    selectimage_type:'api/admin/select/image_type',//图片类型
    selectitem_type:'api/admin/select/item_type',//应用位置
    style_type:'api/admin/select/style_type',//类型
    enclosure:'api/admin/enclosure',//附件上传模板列表
    enclosure_detail:'api/admin/enclosure_detail',//附件上传模板详情
    question_type_set_status:'api/admin/question_type_set_status',//附件上传分类状态变更
    question_type_detail:'api/admin/question_type_detail',//附件上传分类详情
    damage:'api/admin/damage',//获取违约金信息和编辑违约金信息
    op_amount:'api/admin/op_amount',//计算逾期支付服务费
    dn_amount:'api/admin/dn_amount',//计算延迟告知退赔
    rti_amount:'api/admin/rti_amount',//拒绝告知退赔
    roa_amount:'api/admin/roa_amount',//拒绝协助退赔
    fj:'api/admin/fj',//补充资料信息列表
    fj_info:'api/admin/fj_info',//补充资料详情
    ssqd:'api/admin/ssqd',//损失清单列表
    ssqd_info:'api/admin/ssqd_info',//损失清单详情
    zxts:'api/admin/zxts',//自行投诉列表
    zxts_info:'api/admin/zxts_info',//自行投诉详情
    hssqd:'api/admin/hssqd',//损失清单列表历史记录
    hssqd_info:'api/admin/hssqd_info',//损失清单详情历史记录
    hzxts:'api/admin/hzxts',//自行投诉记录列表历史记录
    hzxts_info:'api/admin/hzxts_info',//自行投诉记录详情历史记录
    fj_history:'api/admin/fj_history',//历史记录
    fj_history_info:'api/admin/fj_history_info',//历史记录详情
    question:'api/admin/question',//问卷列表
    question_detail:'api/admin/question_detail',//问卷详情
    blist:'api/admin/blist',//订单触发列表
    bind_detail:'api/admin/bind_detail',//订单触发详情
    bind:'api/admin/bind',//订单触发
    ota:'api/admin/ota',//ota列表
    otaInfo:'api/admin/otaInfo',//ota详情
    bind_user:'api/admin/bind_user',//问卷发布列表
    bind_user_detail:'api/admin/bind_user_detail',//问卷发布-详情
    fj_append:'api/admin/fj_append',//追加附件资料
    fj_question:'api/admin/fj_question',//问题模板列表
    fj_question_detail:'api/admin/fj_question_detail',//问题模板详情
    cptype:'api/admin/select/cptype',//订单筛选投诉类型1
    cpTypeChild:'api/admin/select/cptypechild',//订单筛选投诉类型2
    commonSelect:'api/admin/select/commonSelect',//获取公用下拉框数据
    fee_lock:'api/admin/fee_lock',//锁定订单
    order_schedule_sort:'api/admin/order_schedule_sort',//订单进度排序
    short_list:'api/admin/short_list',//短信列表
    send_batch:'api/admin/send_batch',//批量发送短信
    calculated:'api/admin/calculated',//订单金额计算
    bug_feed_back:'api/admin/bug_feed_back',//意见反馈列表
    bug_feed_back_info:'api/admin/bug_feed_back_info',//意见反馈详情
    bug_feed_back_log:'api/admin/bug_feed_back_log',//意见反馈处理记录列表
    sclistExport:'api/admin/sclistExport',//支付成功订单导出
    order_chart:'api/admin/order_chart',//首页订单图表数据
    order_count:'api/admin/order_count',//首页订单数据统计
};

export default config;