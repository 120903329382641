<template>
  <al-page>
     <el-image
      :src="require('../images/logo.jpg')"
      fit="fill"></el-image>
  </al-page>
</template>

<script>
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      logo: "require('../images/logo.JPG')",
    }
  },
  methods: {
  
  },
}
</script>


<style scoped lang="scss">
.el-menu--horizontal > .el-menu-item{
    font-weight: bold;
}
.el-form--inline .el-form-item__label{
    font-weight: bold;
}
</style>