export default {
    USER_STATUS(val){
        switch(val){
            case '3': return "删除";
            case '2': return "禁用";
            case '1': return "启用";
            case 3: return "删除";
            case 2: return "禁用";
            case 1: return "启用";
            default: return val;
        }
    },
    Semantization(val){
        switch(val){
            case '2': return "隐藏";
            case '1': return "展示";
            case 2: return "隐藏";
            case 1: return "展示";
            default: return val;
        }
    }
}