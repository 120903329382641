import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { requireAuth: true },
    component: Home,
    redirect: "/views/logo",
    children:[{
      path: '/views/logo',
      name: 'logo',
      component: () => import('../views/logo.vue')
    },{
      path: '/views/personal',
      name: 'personal',
      component: () => import('../views/personal.vue')
    },{
      path: '/menu/MenuList',
      name: 'MenuList',
      component: () => import('../views/menu/MenuList.vue')
    },
    {
      path: '/menu/MenuAdd',
      name: 'MenuAdd',
      component: () => import('../views/menu/MenuAdd.vue')
    },
    {
      path: '/menu/MenuEdit',
      name: 'MenuEdit',
      component: () => import('../views/menu/MenuEdit.vue')
    },
    {
      path: '/role/roleadd',
      name: 'roleadd',
      component: () => import('../views/role/roleadd.vue')
    },
    {
      path: '/role/RoleList',
      name: 'RoleList',
      component: () => import('../views/role/RoleList.vue')
    },
    {
      path: '/role/roleedit',
      name: 'roleedit',
      component: () => import('../views/role/roleedit.vue')
    },
    {
      path: '/role/rolebind',
      name: 'rolebind',
      component: () => import('../views/role/rolebind.vue')
    },
    {
      path: '/user/userlist',
      name: 'userlist',
      component: () => import('../views/user/userlist.vue')
    },
    {
      path: '/user/useradd',
      name: 'useradd',
      component: () => import('../views/user/useradd.vue')
    },
    {
      path: '/user/useredit',
      name: 'useredit',
      component: () => import('../views/user/useredit.vue')
    },
    {
      path: '/route/routelist',
      name: 'routelist',
      component: () => import('../views/route/routelist.vue')
    },
    {
      path: '/route/routeadd',
      name: 'routeadd',
      component: () => import('../views/route/routeadd.vue')
    },
    {
      path: '/route/routeedit',
      name: 'routeedit',
      component: () => import('../views/route/routeedit.vue')
    },
    {
      path: '/order/list',
      name: 'articlelistv',
      component: () => import('../views/order/list.vue')
    },
    {
      path: '/order/list2',
      name: 'articlelistv2',
      component: () => import('../views/order/list2.vue')
    },
    {
      path: '/order/Successfully_paid',
      name: 'SuccessfullyPaid',
      component: () => import('../views/order/Successfully_paid.vue')
    },
    {
      path: '/order/Failed_orders',
      name: 'FailedOrders',
      component: () => import('../views/order/Failed_orders.vue')
    },
    {//收款信息管理
      path: '/order/Collection_information',
      name: 'CollectionInformation',
      component: () => import('../views/order/Collection_information.vue')
    },
    {//图片类型管理
      path: '/order/picture_management',
      name: 'PictureManagement',
      component: () => import('../views/order/picture_management.vue')
    },
    {//模板管理
      path: '/order/template_management',
      name: 'TemplateManagement',
      component: () => import('../views/order/template_management.vue')
    },
    {//问题模板
      path: '/order/fj_question',
      name: 'FjQuestion',
      component: () => import('../views/order/fj_question.vue')
    },
    {//问题分类管理
      path: '/order/Problem_classification_management',
      name: 'ProblemClassificationManagement',
      component: () => import('../views/order/Problem_classification_management.vue')
    },
    {//ota管理
      path: '/order/ota',
      name: 'ota',
      component: () => import('../views/order/ota.vue')
    },
    {//限时行为统计
      path: '/order/behavior_statistics',
      name: 'BehaviorStatistics',
      component: () => import('../views/order/behavior_statistics.vue')
    },
    {//用户主动联系客服
      path: '/order/exchangeby_order',
      name: 'exchangeby_order',
      component: () => import('../views/order/exchangeby_order.vue')
    },
    {//问卷
      path: '/order/questionnaire',
      name: 'Questionnaire',
      component: () => import('../views/order/questionnaire.vue')
    },
    {//意见反馈列表
      path: '/other/bugfeed',
      name: 'Bugfeed',
      component: () => import('../views/other/bugfeed.vue')
    },
    {//短信管理
      path: '/other/sms_management',
      name: 'sms_management',
      component: () => import('../views/other/sms_management.vue')
    },
  ],
  },
  {
      path: '*',
      redirect: '/'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }
]
console.log(process.env.BASE_URL)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
  } else {
   let token = localStorage.getItem('Authorization');
    if (token === 'null' || token === null || token === '' || token === undefined) {//token 为空
      next('/login');
    } else if(new Date().getTime() - localStorage.getItem('lasttime') > 18000000){//超过5小时无动作
      localStorage.setItem("Authorization", '');
      next('/login');
    }else{
      next();
    }
  }
});

export default router
