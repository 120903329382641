const formatDate = (_date,type,num) => {
    if(_date == '' || !_date || _date == '0000-00-00'){
        return '';
    }
    // if(_date == '0000-00-00'){
    //     return _date;
    // }
        let date = new Date(_date);
        let m = date.getMonth() + 1;
        m = m < 10 ? '0' + m : m ;
        let d = date.getDate();
        d = d < 10 ? '0' + d : d ;
    if(type == 1){
        return date.getFullYear() +'-'+ m + '-' + d + ' ' + (date.getHours()<10?'0'+date.getHours():date.getHours()) + ':' 
        + (date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes()) + ':' + (date.getSeconds()<10?'0'+date.getSeconds():date.getSeconds());
    }else if(type == 2){
        return date.getFullYear() + m  + d  + (date.getHours()<10?'0'+date.getHours():date.getHours()) 
        + (date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes()) + (date.getSeconds()<10?'0'+date.getSeconds():date.getSeconds());
    }else if(type == 3){
        return date.getFullYear() +'-'+ m +'-' + d + ' ' + num.slice(0,2)+ ':' + num.slice(2,4)+ ':' +num.slice(4,6);
    }else{
        return date.getFullYear() +'-'+ m + '-' + d
    }
};

export default formatDate;